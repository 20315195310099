import React, { useState, useEffect } from 'react'
import './layout.css'
import { HashRouter, Route, Switch } from 'react-router-dom'
import IndexPage from '../containers/index_page/index.js'
import NewIndexPage from '../containers/new_index_page/index.js'
import DownloadPage from '../containers/download_page/index.js'
import SchoolEntry from '../containers/school_entry/index.js'
import AboutUs from '../containers/about_us/index.js'
import UserAgreement from '../containers/agreement_page/user_agreement.js'
import PrivacyPolicy from '../containers/agreement_page/privacy_policy.js'
import TechnologySupport from '../containers/technology_support/index.js'
import Logo from '../assets/imgs/LOGO-min.png'
import { message } from 'antd'
// 解决hashHistory的导入问题
import { createHashHistory } from 'history';
const hashHistory = createHashHistory();

function Layout () {
    const menu = [
        {
            title: '首页',
            id: 'page1'
        },
        // {
        //     title: '教师端',
        //     id: 'page2',
        //     method: () => {
        //         window.open('http://aixue_front.aifoxer.com/#/')
        //     }
        // },
        // {
        //     title: '下载应用',
        //     id: 'page3'
        // },
        {
            title: '关于我们',
            id: 'page4'
        }
    ]

    const [switchPage, setSwitchPage] = useState('') // 切换界面
    const [isChatUs, setIsChatUs] = useState(false) // 是否是
    const [boardVisible, setBoardVisible] = useState(false) // 是否显示board

    useEffect(() => {
        setSwitchPage(window.location.hash.split('/')[1].split('?')[0] || 'page1')
    }, [])

    useEffect(() => {
        if (isChatUs) {
            window.location.hash = switchPage + '?chat_us'
        } else {
            window.location.hash = switchPage
        }
    }, [switchPage, isChatUs])

    return  (
        <div className="layout_container">
            {/* 头部 */}
            <div
                className="header_container"
                style={{ boxShadow: switchPage === 'page1' ? '0px 2px 25px 0px #4973A5' : '0px 2px 25px 0px #C1CEDE' }}
            >
                <div className="header_content">
                    <div
                        onClick={() => {
                            setSwitchPage('page1')
                            setIsChatUs(false)
                            window.location.hash = '#/page1'
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={Logo} alt="logo" className="header_logo" />
                    </div>
                    <ul className="header_right_container">
                        {
                            menu.map((item, index) => {
                                return  (
                                    <li
                                        className={switchPage === item.id ? "header_right_item header_right_item_active" : "header_right_item"}
                                        onClick={() => {
                                            if (item.method) {
                                                item.method()
                                            }
                                            if (item.id === 'page2' || item.id === 'page3') {
                                                return
                                            }
                                            setSwitchPage(item.id)
                                        }}
                                        key={index}
                                    >
                                        {item.title}
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <img
                        className="header_menu_logo"
                        src={require('../assets/imgs/menu.png')}
                        style={{ width: '26px', display: 'none' }}
                        onClick={() => {
                            setBoardVisible(true)
                        }}
                    />
                </div>
            </div>
            <HashRouter>
                <Switch>
                    {/* <Route
                        exact
                        path="/"
                        component={IndexPage}
                    /> */}
                    <Route
                        exact
                        path="/"
                        component={() => {
                            return  <NewIndexPage setSwitchPage={setSwitchPage} setIsChatUs={setIsChatUs} />
                        }}
                    />
                    {/* <Route
                        exact
                        path="/page1"
                        component={IndexPage}
                    /> */}
                    <Route
                        exact
                        path="/page1"
                        component={() => {
                            return  <NewIndexPage setSwitchPage={setSwitchPage} setIsChatUs={setIsChatUs} />
                        }}
                    />
                    <Route
                        exact
                        path="/page2"
                        component={DownloadPage}
                    />
                    <Route
                        exact
                        path="/page3"
                        component={SchoolEntry}
                    />
                    <Route
                        exact
                        path="/page4"
                        component={AboutUs}
                    />
                    <Route
                        exact
                        path="/user_agreement"
                        component={UserAgreement}
                    />
                    <Route
                        exact
                        path="/privacy_policy"
                        component={PrivacyPolicy}
                    />
                    <Route
                        exact
                        path="/technical_support"
                        component={TechnologySupport}
                    />
                </Switch>
            </HashRouter>
            <div
                className="menu_board"
                style={{ display: boardVisible ? 'block' : 'none' }}
                onClick={() => {
                    setBoardVisible(false)
                }}
            >
                <div className="menu_white_board">
                    <p className="menu_p">Menu</p>
                    <ul className="menu_ul">
                        <li
                            className={switchPage === 'page1' ? "menu_active_li" : ''}
                            onClick={() => {
                                setSwitchPage('page1')
                                setIsChatUs(false)
                            }}
                        >
                            首页
                        </li>
                        {/* <li
                            onClick={() => {
                                window.open('http://aixue_front.aifoxer.com/#/')
                            }}
                        >
                            教师端
                        </li> */}
                        {/* <li>下载应用</li> */}
                        <li
                            className={switchPage === 'page4' && !isChatUs ? "menu_active_li" : ''}
                            onClick={() => {
                                setIsChatUs(false)
                                setSwitchPage('page4')
                            }}
                        >
                            关于我们
                        </li>
                        <li
                            className={switchPage === 'page4' && isChatUs ? "menu_active_li" : ''}
                            onClick={() => {
                                setIsChatUs(true)
                                setSwitchPage('page4')
                            }}
                        >
                            联系我们
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    )
}

export default Layout;