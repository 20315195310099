import React, { useState } from 'react'
import './index.css'
import {iPostTechnologySupport} from '../../api/index.js'
import { message } from 'antd'

function TechnologySupport () {
    const [inputVal, setInputVal] = useState('')
    
    const uploadInputVal = () => {
        if (!inputVal) {
            message.error('请填写您的问题')
            return
        }
        message.success('提交成功，感谢您的反馈')
        setInputVal('')
        // const query = {
        //     content: inputVal
        // }
        // iPostTechnologySupport(query)
        // .then(() => {
        //     message.success('提交成功，感谢您的反馈')
        //     setInputVal('')
        // })
    }

    return  (
        <div className="technology_support_container">
            <p style={{ width: '90%', textAlign: 'center' }}>当您遇到问题可以通过发送邮件到apple@aifoxer.com或在此匿名提交（在此匿名提交将无法获得反馈）。</p>
            <div className="technology_support_input_div">
                <textarea
                    value={inputVal}
                    onChange={(e) => {
                        setInputVal(e.target.value)
                    }}
                    className="technology_support_input"
                    placeholder="请输入您想反馈的问题"
                ></textarea>
                
            </div>
            <div className="upload_submit" onClick={uploadInputVal}>提交</div>
        </div>
    )
}

export default TechnologySupport;