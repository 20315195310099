import React, { useState, useEffect } from 'react'
import './index.css'
import ReactPlayer from 'react-player'
import SwipeableViews from 'react-swipeable-views';

let scroll_time

function NewIndexPage ({ setSwitchPage, setIsChatUs }) {
    const [count, setCount] = useState(0)
    const [switchImg, setSwitchImg] = useState(0)
    const [videoVisible, setVideoVisible] = useState(false)

    const switchImgMethod = () => {
        if (switchImg) {
            setSwitchImg(0)
        } else {
            setSwitchImg(1)
        }
    }

    useEffect(() => {
        scroll_time = setInterval(() => {
            switchImgMethod()
        }, 5000);

        return  () => {
            clearInterval(scroll_time)
        }
    })

    return  (
        <div style={{ width: '100%' }}>
            <div className="new-first-page">
                <p>首款结合手写识别、自动批阅</p>
                <p>与分步讲解的教学应用</p>
                <img src={require('../../assets/index_page/0-min.png')} alt="图片" />
                {/* <div
                    className="new-video-icon"
                    onClick={() => {
                        setVideoVisible(true)
                    }}
                >
                    <Icon type="caret-right" style={{ fontSize: '40px', color: '#FFF' }} />
                </div> */}
            </div>
            <div className="new-second-page">
                <h3>收录全套初中数学教材</h3>
                <p>
                    <span>
                        <span className="new-num">12</span>
                        <span className="new-desc">个版本</span>
                    </span>
                    <span>
                        <span className="new-num">72</span>
                        <span className="new-desc">本教材</span>
                    </span>
                    <span>
                        <span className="new-num">1344</span>
                        <span className="new-desc">个章节</span>
                    </span>
                </p>
                <img src={require('../../assets/index_page/1-min.png')} alt="图片" />
            </div>
            <div className="new-third-page">
                <div className="new-container">
                    <img src={require('../../assets/index_page/2-min.jpg')} alt="图片" />
                    <div>
                        <h3 className="lg-screen">公式图形，轻松识别</h3>
                        <h3 className="sm-screen">公式图形</h3>
                        <h3 className="sm-screen">轻松识别</h3>
                        <div>
                            <div>
                                <img className="new-icon-bg" src={require('../../assets/index_page/2-min-icon-1.png')} />
                                <p className="new-p1">适用性广</p>
                                <p className="new-p2 sm-screen">能够识别数字、字母以及常用汉字，支持上标、下标、分式、根式、联立、公式编号等初中数学范围内常用的数学书写</p>
                                <p className="new-p2 lg-screen">能够识别数字、字母以及常用汉字，支持上标、下标、分式、</p>
                                <p className="new-p2 lg-screen">根式、联立、公式编号等初中数学范围内常用的数学书写</p>
                            </div>
                            <div>
                                <img className="new-icon-bg" src={require('../../assets/index_page/2-min-icon-2.png')} />
                                <p className="new-p1">准确率高</p>
                                <p className="new-p2 sm-screen">基于复杂神经网络和计算机视觉技术，识别准确率达到99%，适应各种书写方式与习惯</p>
                                <p className="new-p2 lg-screen">基于复杂神经网络和计算机视觉技术，识别准确率达到99%，</p>
                                <p className="new-p2 lg-screen">适应各种书写方式与习惯</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-forth-page">
                <h3>内建强大草稿工具</h3>
                <img src={require('../../assets/index_page/4-caogao.jpg')} alt="图片" />
                <img className="chizi" src={require('../../assets/index_page/4-chizi.jpg')} />
                <img className="shou" src={require('../../assets/index_page/4-shou.png')} />
            </div>
            <div className="new-fifth-page">
                <div className="new-container">
                    <div>
                        <h3 className="lg-screen">还原真实的批阅过程</h3>
                        <h3 className="sm-screen">还原真实</h3>
                        <h3 className="sm-screen">批阅过程</h3>
                        <div>
                            <div>
                                <img className="new-icon-bg" src={require('../../assets/index_page/5-min-icon-1.png')} />
                                <p className="new-p1">自动批改</p>
                                <p className="new-p2 sm-screen">采用智能语义理解技术，将教师从繁重的作业批改工作中解放出来</p>
                                <p className="new-p2 lg-screen">采用智能语义理解技术，将教师从繁重的作业批改</p>
                                <p className="new-p2 lg-screen">工作中解放出来</p>
                            </div>
                            <div>
                                <img className="new-icon-bg" src={require('../../assets/index_page/5-min-icon-2.png')} />
                                <p className="new-p1">面向过程</p>
                                <p className="new-p2 sm-screen">基于专业教研团队细致拆分的解题步骤构建知识库，批改结果精准定位学生薄弱环节</p>
                                <p className="new-p2 lg-screen">基于专业教研团队细致拆分的解题步骤构建知识库</p>
                                <p className="new-p2 lg-screen">批改结果精准定位学生薄弱环节</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="img-container"
                    >
                        <img src={require('../../assets/index_page/6-min.png')} />
                    </div>
                </div>
            </div>
            <div className="new-sixth-page">
                <div className="new-container">
                    <h3 className="sm-screen">互动教学 强化练习</h3>
                    <img src={require('../../assets/index_page/7-min.png')} />
                    <div className="sm-screen" style={{ width: '100%', height: '1px' }}></div>
                    <div>
                        <h3 className="lg-screen">互动教学 强化练习</h3>
                        <div>
                            <div>
                                <img className="new-icon-bg" src={require('../../assets/index_page/7-min-icon-1.png')} />
                                <p className="new-p1">交互式分布讲解</p>
                                <p className="new-p2">通过细致拆分解题步骤，采用一问一答的启发式讲解，</p>
                                <p className="new-p2">引导学生循序渐进修改错题</p>
                            </div>
                            <div>
                                <img className="new-icon-bg" src={require('../../assets/index_page/7-min-icon-2.png')} />
                                <p className="new-p1">智能化内容推送</p>
                                <p className="new-p2">基于学习历史和错误定位，结合完整的知识体系构建，</p>
                                <p className="new-p2">准确向学生推送练习内容</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-seventh-page">
                <img src={require('../../assets/index_page/8-1-min.png')} />
                <img src={require('../../assets/index_page/8-2-min.png')} />
                <div>
                    <h3 className="lg-screen">蓝狐Ai学更贴心，学习从此爱不释手</h3>
                    <h3 className="sm-screen">蓝狐Ai学更贴心</h3>
                    <h3 className="sm-screen">学习从此爱不释手</h3>
                    <div>
                        <img src={require('../../assets/index_page/8-0-min.png')} />
                        <img src={require('../../assets/index_page/8-4-min.png')} />
                    </div>
                </div>
            </div>
            <div className="new-eighth-page">
                <h3>机构端页面展示</h3>
                <div>
                    <SwipeableViews
                        enableMouseEvents
                        index={switchImg}
                        onChangeIndex={index => {setSwitchImg(index)}}
                    >
                        <div className="jigou-img first-img"></div>
                        <div className="jigou-img second-img"></div>
                    </SwipeableViews>
                </div>
                <ul>
                    <li
                        className={switchImg === 0 ? "active" : ""}
                        onClick={() => {
                            setSwitchImg(0)
                        }}
                    ></li>
                    <li
                        className={switchImg === 1 ? "active" : ""}
                        onClick={() => {
                            setSwitchImg(1)
                        }}
                    ></li>
                </ul>
            </div>
            <div className="new-ninth-page">
                <h3>探索未来</h3>
                <p className="lg-screen">蓝狐Ai学将会加入中小学全年级的语文、英语、物理、化学等学科，而初中数学仅仅是开始。</p>
                <p className="sm-screen">蓝狐Ai学将会加入中小学全年级的语文、英语、物理、化学等学科，</p>
                <p className="sm-screen">而初中数学仅仅是开始。</p>
                <img src={require('../../assets/index_page/10-min.png')} />
            </div>
            <div className="new-tenth-page">
                <img src={require('../../assets/index_page/11-min.png')} alt="图片" />
                <h3>iPad 版 蓝狐Ai学</h3>
                <p>限时免费，于 App Store 独家发布</p>
                <div>即将登陆</div>
            </div>
            <footer className="new-footer">
                <div>
                    蓝狐思远科技（北京）有限公司 © 2020 版权所有 &nbsp;&nbsp;&nbsp;&nbsp;京ICP备19021487号-1
                </div>
                <ul className="new-footer_ul">
                    <li
                        onClick={() => {
                            window.location.hash = '#/privacy_policy'
                        }}
                    >
                        隐私条款
                    </li>
                    <li
                        onClick={() => {
                            window.location.hash = '#/technical_support'
                        }}
                    >
                        反馈建议
                    </li>
                    <li>加入我们</li>
                    <li
                        onClick={() => {
                            setIsChatUs(true)
                            setSwitchPage('page4')
                            // window.location.hash = '#/page4?chat_us'
                        }}
                    >
                        联系我们
                    </li>
                </ul>
            </footer>
            {/* 播放视频页 */}
            <div
                className="video_container"
                style={
                    videoVisible ? {} : { display: 'none' }
                }
                onClick={() => {
                    setVideoVisible(false)
                }}
            >
                <div
                    className="video_div"
                >
                    <ReactPlayer
                        url='https://www.w3schools.com/html/mov_bbb.mp4'
                        controls
                        style={{ width: '100%' }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        playing={videoVisible ? true : false}
                    />
                </div>
            </div>
        </div>
    )
}

export default NewIndexPage;