import React, { useState, useEffect } from 'react'
import {Icon} from 'antd'
import { Map, APILoader } from '@uiw/react-baidu-map';
import './index.css'

function AboutUs () {
    const [key, setKey] = useState('')
    const [switchPage, setSwitchPage] = useState('chat_us') //about_us  chat_us
    

    // useEffect(() => {
    //     const marker = new Marker(116.475954, 40.007923)
    //     map.addOverlay(marker);  
    // }, [])

    useEffect(() => {
        if (key) {
            setSwitchPage('chat_us')
        } else {
            setSwitchPage('about_us')
        }
    }, [key])

    useEffect(() => {
        const key = window.location.hash.split('?')[1] ? window.location.hash.split('?')[1] : ''
        setKey(key)
    }, [window.location.hash])

    return  (
        <div className="about_us_page">
            <div className="abcontainer">
                <div className="page_menu">
                    <ul className="page_menu_ul">
                        <li
                            className={switchPage === 'about_us' ? 'li_active' : ''}
                            onClick={() => {
                                setSwitchPage('about_us')
                            }}
                        >
                            关于我们
                        </li>
                        <li
                            className={switchPage === 'chat_us' ? 'li_active' : ''}
                            onClick={() => {
                                setSwitchPage('chat_us')
                            }}
                        >
                            联系我们
                        </li>
                    </ul>
                </div>
                <div className="page_menu_content" style={{ padding: '56px 50px', width: 'calc(100% - 220px)' }}>
                    <div
                        style={{ display: switchPage === 'about_us' ? 'block' : 'none' }}
                    >
                        <h4 className="text_title">关于我们</h4>
                        <p className="text_paragraph">
                            蓝狐AI学，隶属于蓝狐思远科技（北京）有限公司，是首个通过手写识别技术实现复杂解答题批改和讲解的教育类产品。团队核心成员具有清华大学、北京师范大学、华中科技大学等一流名校背景，在计算机视觉以及教育领域有着丰富的实践经验。通过将人工智能技术与教育内容进行深度耦合，构建出由立体化层次化题目组成的题库，为学生提供类似于在纸面上真实作答、辅导老师现场批改讲解的学习交互体验。基于批改讲解过程中学生的反馈，蓝狐AI学能够精准分析学生的知识点掌握情况，构建知识体系，真正实现细粒度的个性化教学，优化学生学习效率。
                        </p>
                        <p className="text_paragraph">&nbsp;</p>
                        <p className="text_paragraph">蓝狐AI学，学习从此爱不释手。</p>
                    </div>
                    <div
                        style={{ display: switchPage === 'chat_us' ? 'block' : 'none' }}
                    >
                        <h4 className="text_title">联系我们</h4>
                        <div className="baidu_map top-map">
                            {/* <APILoader akay="GTrnXa5hwXGwgQnTBG28SHBubErMKm3f">
                                <Map center='北京市朝阳区望京西园222' ref={ref => map = ref} />
                            </APILoader> */}
                        </div>
                        <p className="gray_paragraph"><Icon style={{ color: 'rgba(0, 0, 0, .2)', marginRight: '10px' }} type="phone" theme="filled" />13488881477</p>
                        <p className="gray_paragraph"><Icon style={{ color: 'rgba(0, 0, 0, .2)', marginRight: '10px' }} type="mail" theme="filled" />business@aifoxer.com</p>
                        <p className="gray_paragraph"><Icon style={{ color: 'rgba(0, 0, 0, .2)', marginRight: '10px' }} type="home" theme="filled" />北京市朝阳区星源国际公寓A座2101房间</p>
                        <p className="gray_paragraph">&nbsp;</p>
                        <div className="baidu_map bottom-map">
                            {/* <APILoader akay="GTrnXa5hwXGwgQnTBG28SHBubErMKm3f">
                                <Map center='北京市朝阳区望京西园222' ref={ref => map = ref} />
                            </APILoader> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* 底部 */}
            <footer className="abfooter">
                <div>
                    蓝狐思远科技（北京）有限公司 © 2020 版权所有 &nbsp;&nbsp;&nbsp;&nbsp;京ICP备19021487号-1
                </div>
                <ul className="abfooter_ul">
                    <li
                        onClick={() => {
                            window.location.hash = '#/privacy_policy'
                        }}
                    >
                        隐私条款
                    </li>
                    <li
                        onClick={() => {
                            window.location.hash = '#/technical_support'
                        }}
                    >
                        反馈建议
                    </li>
                    <li>加入我们</li>
                    <li
                        onClick={() => {
                            window.location.hash = '#/page4?chat_us'
                        }}
                    >
                        联系我们
                    </li>
                </ul>
            </footer>
        </div>
    )
}

export default AboutUs;