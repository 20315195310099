import React, { useEffect, useState } from 'react'
import './index.css'
import {
    getPrivacy
} from '../../api/index.js'

function PrivacyPolicy () {
    const [data, setData] = useState([])

    useEffect(() => {
        // TODO 请求
        getPrivacy()
        .then(value => {
            setData(value.data)
        })
    }, [])

    return  (
        <div className="user_container">
            <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '20px' }}>蓝狐Ai学隐私政策</p>
            {
                data.map((item, index) => {
                    if (item.type === 'text') {
                        return  <p key={index} className="user_text_paragraph">{ '        ' + item.content}</p>
                    }
                    if (item.type === 'title') {
                        return  <p key={index} className="user_text_title">{item.content}</p>
                    }
                })
            }
        </div>
    )
}

export default PrivacyPolicy;