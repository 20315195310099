import axios from 'axios'

/* 获取用户协议 */
export const getUserAgreement = () => {
    return axios.get(`/api/document/user_agreement`)
}

/* 获取隐私政策 */
export const getPrivacy = () => {
    return  axios.get(`/api/document/privacy`)
}

export const iPostTechnologySupport = () => {
    return
    // return  axios.post(`/api`)
}