import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import './index.css'

function IndexPage () {
    const [videoVisible, setVideoVisible] = useState(false)
    const [switchImg, setSwitchImg] = useState('page1')
    
    return  (
        <div className="page_container">
            {/* 第一页 */}
            <div className="full_page_1">
                <div className="page_1_container">
                    <h4 className="full_page_1_title">首款结合手写识别、自动批阅与逐步讲解的教学应用</h4>
                    <div style={{ position: 'relative' }}>
                        <img src={require('../../assets/index_page/0-min.png')} alt="图片" className="full_page_1_img" />
                        {/* <div
                            className="video_icon_bg"
                            onClick={() => {
                                setVideoVisible(true)
                            }}
                        >
                            <div className="video_icon_play"></div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* 第二页 */}
            <div className="full_page_2">
                <div className="page_2_container">
                    <h4 className="page_2_title">收录全套初中数学教材</h4>
                    <div className="page_2_top_div">
                        <div className="page_2_top_div_item">
                            <span className="page_2_num_title">
                                12
                            </span>
                            <span className="page_2_text_title">
                                大出版社
                            </span>
                        </div>
                        <div className="page_2_top_div_item">
                            <span className="page_2_num_title">
                                178
                            </span>
                            <span className="page_2_text_title">
                                本教材
                            </span>
                        </div>
                        <div className="page_2_top_div_item" style={{ border: 'none' }}>
                            <span className="page_2_num_title">
                                3842
                            </span>
                            <span className="page_2_text_title">
                                个知识点
                            </span>
                        </div>
                    </div>
                    <img src={require('../../assets/index_page/1-min.png')} className="full_page_2_img_bg" />
                </div>
            </div>
            {/* 第三页 */}
            <div className="full_page_3">
                <div className="full_page_3_container">
                    <img src={require('../../assets/index_page/2-min.png')} alt="背景" className="full_page_3_img_bg" />
                    <div className="page_3_right_container">
                        <h4 className="full_page_3_title" style={{ width: '92%' }}>公式图形轻松识别</h4>
                        <div className="full_page_3_item">
                            <img src={require('../../assets/index_page/2-min-icon-1.png')} />
                            <p className="full_page_3_item_title">适用性广</p>
                            <p className="full_page_3_item_content">能够识别数字、字母以及常用汉字，支持上标、下标、分式、根式、联立、公式编号等初中数学范围内常用的数学书写</p>
                        </div>
                        <div className="full_page_3_item">
                            <img src={require('../../assets/index_page/2-min-icon-2.png')} />
                            <p className="full_page_3_item_title">准确率高</p>
                            <p className="full_page_3_item_content">基于复杂神经网络和计算机视觉技术，识别准确率达到99%，适应各种书写方式与习惯</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* 第四页 */}
            <div className="full_page_4">
                <h4 className="full_page_4_title">内建强大草稿工具</h4>
                <div style={{ width: '100%', height: '90%', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
                    {/* <img src={require('../../assets/index_page/draft_bg.png')} alt="背景" style={{ height: '110%' }} /> */}
                    {/* <img /> */}
                    <div className="chizi"></div>
                    <div className="shou"></div>
                </div>
            </div>
            {/* 第五页 */}
            <div className="full_page_5">
                <div className="full_page_5_container">
                    <div className="page_5_content_container">
                        <h4 className="full_page_3_title">还原真实批阅过程</h4>
                        <div className="full_page_3_item">
                            <img src={require('../../assets/index_page/5-min-icon-1.png')} />
                            <p className="full_page_3_item_title">自动批改</p>
                            <p className="full_page_3_item_content" style={{ width: '55%' }}>采用智能语义理解技术，将教师从繁重的作业批改工作中解放出来</p>
                        </div>
                        <div className="full_page_3_item">
                            <img src={require('../../assets/index_page/5-min-icon-2.png')} />
                            <p className="full_page_3_item_title">面向过程</p>
                            <p className="full_page_3_item_content" style={{ width: '55%' }}>基于专业教研团队细致拆分的解题步骤构建知识库，批改结果精准定位学生薄弱环节</p>
                        </div>
                    </div>
                    <img src={require('../../assets/index_page/6-min.png')} className="full_page_5_img_bg" style={{ marginTop: '20px', marginLeft: '20px' }} />
                </div>
            </div>
            {/* 第六页 */}
            <div className="full_page_6">
                <div className="full_page_3_container">
                    <img src={require('../../assets/index_page/7-min.png')} alt="背景" className="full_page_3_img_bg" />
                    <div>
                        <h4 className="full_page_3_title">互动教学 强化练习</h4>
                        <div className="full_page_3_item">
                            <img src={require('../../assets/index_page/7-min-icon-1.png')} />
                            <p className="full_page_3_item_title">问答交互</p>
                            <p className="full_page_3_item_content">避免盲目向学生灌输答案，以提问和引导的方式循序渐进展开题目讲解。</p>
                        </div>
                        <div className="full_page_3_item">
                            <img src={require('../../assets/index_page/7-min-icon-2.png')} />
                            <p className="full_page_3_item_title">精准推送</p>
                            <p className="full_page_3_item_content">在交互过程中，完整准确记录学生每一步的答案和反馈，向学生精准推送个性化练习，查漏补缺</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* 第七页 */}
            <div className="full_page_7">
                <h4 className="full_page_7_text">蓝狐Ai学更贴心，学习从此爱不释手</h4>
                <div className="full_page_7_container">
                    <img src={require('../../assets/index_page/collection_bg.png')} />
                </div>
            </div>
            {/* 第八页 */}
            <div className="full_page_8">
                <div className="container">
                    <h4 className="full_page_8_title">机构端页面展示</h4>
                    {/* <p className="full_page_8_text">这是一段内容描述这是一段内容描述这是一段内容描述这是一段内容描述</p> */}
                    <ul className="full_page_8_ul">
                        <img src={require('../../assets/index_page/9-0-min.png')} style={switchImg === 'page1' ? {} : {width: 0}} />
                        <img src={require('../../assets/index_page/9-min.png')} style={switchImg === 'page2' ? {} : {width: 0}} />
                    </ul>
                    <ul className="full_page_8_selected">
                        <li
                            className={switchImg === 'page1' ? "active_selected_li" : ''}
                            onClick={() => {
                                setSwitchImg('page1')
                            }}
                        ></li>
                        <li
                            className={switchImg === 'page2' ? "active_selected_li" : ''}
                            onClick={() => {
                                setSwitchImg('page2')
                            }}
                        ></li>
                    </ul>
                </div>
            </div>
            {/* 第九页 */}
            <div className="full_page" style={{ backgroundColor: '#F9F9F9' }}>
                <div className="page_9_top">
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', overflow: 'hidden' }}>
                        <h4 className="text_title" >探索未来</h4>
                        <p className="text_desc">蓝狐Ai学将会加入中小学全年级的语文、英语、物理、化学等学科，而初中数学仅仅是开始。</p>
                        <img className="page_9_bg" style={{ marginTop: '60px' }} src={require('../../assets/index_page/10-min.png')} alt="图片" />
                    </div>
                </div>
                <div className="page_9_bottom">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img className="page_9_logo" src={require('../../assets/index_page/11-min.png')} alt="logo" />
                        <h5 className="bottom_title">iPad 版 蓝狐Ai学</h5>
                        <p className="bottom_desc">限时免费，于 App Store 独家发布</p>
                        <div className="download_button">立即下载</div>
                    </div>
                </div>
            </div>
            {/* 底部 */}
            <footer className="footer">
                <div>
                    蓝狐思远科技（北京）有限公司  © 2020 版权所有
                </div>
                <ul className="footer_ul">
                    <li>隐私条款</li>
                    <li>反馈建议</li>
                    <li>加入我们</li>
                    <li>联系我们</li>
                </ul>
            </footer>
            {/* 播放视频页 */}
            <div
                className="video_container"
                style={
                    videoVisible ? {} : { display: 'none' }
                }
                onClick={() => {
                    setVideoVisible(false)
                }}
            >
                <div
                    className="video_div"
                >
                    <ReactPlayer
                        url='https://www.w3schools.com/html/mov_bbb.mp4'
                        controls
                        style={{ width: '100%' }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        playing={videoVisible ? true : false}
                    />
                </div>
            </div>
        </div>
    )
}

export default IndexPage;